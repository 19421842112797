import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/marcodamm/personal/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Contact Me" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <h1>{`Contact`}</h1>
    <p>{`If you have a job offer, would like to connect or have any comments and/or questions regarding my
content, please feel free to contact me via `}<strong>{`mail(at)marco-damm.de`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      